import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { useDisclosurePrimitiveState, DisclosurePrimitive, DisclosurePrimitiveContent } from '@twilio-paste/disclosure-primitive';
import Changelog from '@twilio-paste/disclosure-primitive/CHANGELOG.md';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { BasicExample, ConditionallyRenderingExample, MultipleExample } from '../../../component-examples/DisclosurePrimitiveExamples';
export const pageQuery = graphql`
  {
    allPastePrimitive(filter: {name: {eq: "@twilio-paste/disclosure-primitive"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/primitives/disclosure-primitive/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Disclosure Primitive" categoryRoute={SidebarCategoryRoutes.PRIMITIVES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/primitives/disclosure" storybookUrl="/?path=/story/primitives-disclosure" data={props.data.allPastePrimitive.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About the Disclosure Primitive`}</h3>
        <p>{`The Disclosure primitive is an unstyled, functional version of a Disclosure.
It can be used to build a component following the `}<a parentName="p" {...{
            "href": "https://www.w3.org/TR/wai-aria-practices/#disclosure"
          }}>{`WAI-ARIA Disclosure`}</a>{`
pattern. Our Disclosure component is built on top of this primitive.`}</p>
        <p>{`These unstyled primitives are to be used when the styled Disclosure provided by Paste
doesn’t meet the requirements needed to solve a unique customer problem. At that
point, you are welcome to fallback to this functional primitive to roll your own
styled disclosure while still providing a functional and accessible experience to
your customers.`}</p>
        <p>{`This primitive should be used to compose all custom Disclosures to ensure
accessibility and upgrade paths.`}</p>
        <Callout variant="warning" mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Warning</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    We strongly suggest that all components built on top of this primitive get reviewed by the Design Systems team and
    goes through the UX Review process to ensure an excellent experience for our customers.
  </CalloutText>
        </Callout>
        <h2>{`Usage Guide`}</h2>
        <p>{`This package is a wrapper around `}<a parentName="p" {...{
            "href": "https://reakit.io/docs/menu/"
          }}>{`Reakit's Disclosure`}</a>{`.
If you’re wondering why we wrapped that package into our own, we reasoned that
it would be best for our consumers’ developer experience. For example:`}</p>
        <ul>
          <li parentName="ul">{`We can control which APIs we expose and how to expose them. For example, in this package we rename
and export only some of the source package's exports.`}</li>
          <li parentName="ul">{`If we want to migrate the underlying nuts and bolts in the future, Twilio products that
depend on this primitive would need to replace all occurrences of `}<inlineCode parentName="li">{`import … from ‘@reach/dialog’`}</inlineCode>{`
to `}<inlineCode parentName="li">{`import … from ‘@some-new/package’`}</inlineCode>{`. By wrapping it in `}<inlineCode parentName="li">{`@twilio-paste/disclosure-primitive`}</inlineCode>{`,
this refactor can be avoided. The only change would be a version bump in the package.json file.`}</li>
          <li parentName="ul">{`We can more strictly enforce semver and backwards compatibility than some of our dependencies.`}</li>
          <li parentName="ul">{`We can control when to provide an update and which versions we allow, to help reduce
potential bugs our consumers may face.`}</li>
        </ul>
        <h3>{`Installation`}</h3>
        <p>{`This package is available individually or as part of `}<inlineCode parentName="p">{`@twilio-paste/core`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{}}>{`yarn add @twilio-paste/disclosure-primitive - or - yarn add @twilio-paste/core
`}</code></pre>
        <h3>{`Examples`}</h3>
        <h4>{`Basic Example`}</h4>
        <LivePreview scope={{
          useDisclosurePrimitiveState,
          DisclosurePrimitive,
          DisclosurePrimitiveContent
        }} language="jsx" mdxType="LivePreview">
  {BasicExample}
        </LivePreview>
        <h4>{`Conditional Rendering Example`}</h4>
        <p>{`You shouldn't conditionally render the DisclosurePrimitiveContent component as
this will make some Reakit features not work. Instead, you can use render
props and conditionally render the underneath element:`}</p>
        <LivePreview scope={{
          useDisclosurePrimitiveState,
          DisclosurePrimitive,
          DisclosurePrimitiveContent
        }} language="jsx" mdxType="LivePreview">
  {ConditionallyRenderingExample}
        </LivePreview>
        <h4>{`Multiple Components`}</h4>
        <p>{`Each DisclosurePrimitiveContent component should have its own `}<inlineCode parentName="p">{`useDisclosureState`}</inlineCode>{`.
This is also true for derivative modules like Dialog, Popover, Menu, Tooltip etc.`}</p>
        <p>{`If you want to have only one DisclosurePrimitive element controling multiple `}<inlineCode parentName="p">{`DisclosurePrimitiveContent`}</inlineCode>{`
components, you can use render props to apply the same state to different
Disclosures that will result in a single element.`}</p>
        <LivePreview scope={{
          useDisclosurePrimitiveState,
          DisclosurePrimitive,
          DisclosurePrimitiveContent
        }} language="jsx" mdxType="LivePreview">
  {MultipleExample}
        </LivePreview>
        <h3>{`API`}</h3>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    Much of the following is copied directly from{' '}
    <Anchor href="https://reakit.io/docs/disclosure/#props" mdxType="Anchor">Reakit's docs</Anchor>. Because we may update at a different
    cadence, we're duplicating the docs here to prevent information fragmentation.
  </CalloutText>
        </Callout>
        <h4>{`useDisclosurePrimitiveState Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`bool`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animated?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`number or boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5><inlineCode parentName="h5">{`baseId`}</inlineCode>{` prop`}</h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`visible`}</inlineCode>{` prop`}</h5>
        <p>{`Whether it's visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`animated`}</inlineCode>{` prop`}</h5>
        <p>{`If true, animating will be set to true when visible is updated. It'll wait
for stopAnimation to be called or a CSS transition ends. If animated is set
to a number, stopAnimation will be called only after the same number of
milliseconds have passed.`}</p>
        <h4>{`DisclosurePrimitive Props`}</h4>
        <p>{`All the regular HTML attributes (`}<inlineCode parentName="p">{`role`}</inlineCode>{`, `}<inlineCode parentName="p">{`aria-*`}</inlineCode>{`, `}<inlineCode parentName="p">{`type`}</inlineCode>{`, and so on) including the following custom props:`}</p>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`disabled?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`focusable?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5><inlineCode parentName="h5">{`disabled`}</inlineCode>{` prop`}</h5>
        <p>{`Same as the HTML attribute.`}</p>
        <h5><inlineCode parentName="h5">{`focusable`}</inlineCode>{` prop`}</h5>
        <p>{`When an element is `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, it may still be `}<inlineCode parentName="p">{`focusable`}</inlineCode>{`. It works
similarly to `}<inlineCode parentName="p">{`readOnly`}</inlineCode>{` on form elements. In this case, only
`}<inlineCode parentName="p">{`aria-disabled`}</inlineCode>{` will be set.`}</p>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">State props</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These props are returned by the state hook. You can spread them into this component ({`...state`}) or pass them
    separately. You can also provide these props from your own state logic.
  </CalloutText>
        </Callout>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`toggle`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() => void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5><inlineCode parentName="h5">{`baseId`}</inlineCode>{` prop`}</h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`visible`}</inlineCode>{` prop`}</h5>
        <p>{`Whether it's visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`toggle`}</inlineCode>{` prop`}</h5>
        <p>{`Toggles the visible state`}</p>
        <h4>{`DisclosurePrimitiveContent Props`}</h4>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">State props</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    These props are returned by the state hook. You can spread them into this component ({`...state`}) or pass them
    separately. You can also provide these props from your own state logic.
  </CalloutText>
        </Callout>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`visible`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`baseId`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animated`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`number or boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`animating`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`stopAnimation`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`() => void`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
          </tbody>
        </table>
        <h5><inlineCode parentName="h5">{`baseId`}</inlineCode>{` prop`}</h5>
        <p>{`ID that will serve as a base for all the items IDs.`}</p>
        <h5><inlineCode parentName="h5">{`visible`}</inlineCode>{` prop`}</h5>
        <p>{`Whether it's visible or not.`}</p>
        <h5><inlineCode parentName="h5">{`animated`}</inlineCode>{` prop`}</h5>
        <p>{`If true, animating will be set to true when visible is updated. It'll wait
for stopAnimation to be called or a CSS transition ends. If animated is set
to a number, stopAnimation will be called only after the same number of
milliseconds have passed.`}</p>
        <h5><inlineCode parentName="h5">{`animating`}</inlineCode>{` prop`}</h5>
        <p>{`Whether it's animating or not.`}</p>
        <h5><inlineCode parentName="h5">{`stopAnimation`}</inlineCode>{` prop`}</h5>
        <p>{`Stops animation. It's called automatically if there's a CSS transition.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      